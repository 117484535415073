import barba from '@barba/core';
import SlidingLine from '../classes/slidingLine';

export function slidingLineFeaturedNews() {
  window['featured-news'] = new SlidingLine({
    direction: 'horizontal',
    container: '[data-js="sliding-line-featured-news"]',
    destination: '[data-js="sliding-line-destination"]',
    startingPosition: true,
  });

  window['featured-news'].init();

  barba.hooks.afterLeave(() => {
    window['featured-news'].destroy();
  });
}

export function slidingLinePagination() {
  window.pagination = new SlidingLine({
    direction: 'horizontal',
    container: '[data-js="sliding-line-pagination"]',
    destination: '[data-js="sliding-line-destination"]',
    startingPosition: true,
  });

  window.pagination.init();

  barba.hooks.afterLeave(() => {
    window.pagination.destroy();
  });
}

export function slidingLineConvention() {
  window.convention = new SlidingLine({
    direction: 'vertical',
    container: '[data-js="sliding-line-convention"]',
    destination: '[data-js="sliding-line-destination"]',
  });

  window.convention.init();

  barba.hooks.afterLeave(() => {
    window.convention.destroy();
  });
}
