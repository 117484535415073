import $ from 'jquery';
import LazyLoad from 'vanilla-lazyload';
import { Calendar } from 'fullcalendar';
import frLocale from '@fullcalendar/core/locales/fr';
import { Datepicker } from 'vanillajs-datepicker';
import { isMobile } from './helper';
import { SCROLLFIRE, OBSERVER } from '../plugins';
import rubrics from './rubrics';
import tippy from 'tippy.js';
//import 'tippy.js/dist/tippy.css'; // optional for styling

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function updateLazyLoad() {
  const onResize = () => {
    window.lazyload.update();
  };

  OBSERVER.add({
    name: 'updateLazyLoad',
    events: 'resize',
    function: onResize,
  });

  OBSERVER.on('updateLazyLoad');
}

export function changeSelect(select) {
  const DROPDOWN = document.querySelector(select);

  function triggerChange() {
    const e = new Event('change', { bubbles: true });
    DROPDOWN.dispatchEvent(e);
  }

  if (DROPDOWN) {
    const OPTIONS = DROPDOWN.nextElementSibling.querySelectorAll('.dropdown-option');

    OBSERVER.add({
      name: 'triggerChange',
      events: 'click',
      targets: OPTIONS,
      function: triggerChange,
    });
    OBSERVER.on('triggerChange');
  }
}

// Fonction permettant de show/hide le tooltip du bouton information sur l'image dans une page de détail
export function tooltipPictureCredits() {
  const classActive = 'is-active';

  function onClick(e) {
    const tooltip = e.currentTarget.querySelector('.js-credit-tooltip');

    if (tooltip.classList.contains(classActive) && isMobile()) {
      tooltip.classList.remove(classActive);
    } else {
      tooltip.classList.add(classActive);
    }
  }

  function onMouseEnter(e) {
    if (!isMobile()) {
      e.currentTarget.querySelector('.js-credit-tooltip').classList.add(classActive);
    }
  }

  function onMouseLeave(e) {
    if (!isMobile()) {
      e.currentTarget.querySelector('.js-credit-tooltip').classList.remove(classActive);
    }
  }

  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'mouseenter',
    targets: '.js-credit',
    function: onMouseEnter,
  });
  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'mouseleave',
    targets: '.js-credit',
    function: onMouseLeave,
  });
  OBSERVER.add({
    name: 'tooltipPictureCredits',
    events: 'click',
    targets: '.js-credit',
    function: onClick,
  });
  OBSERVER.on('tooltipPictureCredits');
}

export function conventionsLoaded() {
  rubrics();
  SCROLLFIRE.init();
}

export function galleriesLoaded(data) {
  if (!data.hasMorePages) {
    document.querySelector('.js-more-galleries').style.display = 'none';
  } else {
    document.querySelector('.js-more-galleries').style.display = 'block';
  }
  window.lazyload = new LazyLoad();
}

export function fullCalendar() {
  // Obtenir la liste des événements par API
  const xhttp = new XMLHttpRequest();
  xhttp.open('GET', '/api/v1/events/', false);
  xhttp.send();
  const events = JSON.parse(xhttp.responseText).data;

  const calendarEl = document.querySelector('.js-fullCalendar');
  const fullCalendarItem = new Calendar(calendarEl, {
    timeZone: 'UTC',
    events,
    initialView: document.body.clientWidth < 800 ? 'listMonth' : 'dayGridMonth',
    displayEventTime: false,
    locales: [frLocale],
    height: 'auto',
    firstDay: 0, // Mettre le Dimanche comme le premier jour de la semaine
    eventDisplay: 'block',
    listDayFormat: { day: 'numeric', month: 'short' },
    eventDidMount(info) {
      tippy(info.el, {
        content: info.event.title,
      });
    },
    windowResize(arg) {
      if (document.body.clientWidth < 800) {
        if (arg.view.type !== 'listMonth') {
          this.changeView('listMonth');
        }
      } else {
        this.changeView('dayGridMonth');
      }
    },
  });
  fullCalendarItem.render();
}
