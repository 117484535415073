import Cookies from '../classes/cookies';

// Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
export default function manageCookies() {
  Cookies.waitForElementToDisplay('#s-rall-bn', () => {
    Cookies.updateCookies(false);

    // Boutons accepter, refuser et sauvegarder dans la sidebar
    document.querySelector('#s-all-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-rall-bn').addEventListener('click', Cookies.updateCookies);
    document.querySelector('#s-sv-bn').addEventListener('click', Cookies.updateCookies);

    // Si le cookie cc_cookie_byscuit existe les boutons #c-t-bn et #c-p-bn sont pas init
    const btnDeclinePopup = document.querySelector('#c-t-bn');
    if (btnDeclinePopup) {
      // Boutons accepter et refuser dans le popup
      document.querySelector('#c-p-bn').addEventListener('click', Cookies.updateCookies);
      btnDeclinePopup.addEventListener('click', Cookies.updateCookies);
    }

    if (!getCookie('cc_cookie_byscuit') || !getCookie('cc_cookie_byscuit').includes('functional')) {
      if (document.querySelector('.fr-video')) {
        document.querySelectorAll('.fr-video').forEach((vid) => {
          vid.innerHTML = '<div class="placeholder_auto_div" id="ps-fr-video" byscuit-temp-placeholder=""><div id="pd-fr-video" class="placeholder-txt">Veuillez <a class="linkStyle" onclick="cc.showSettings(0);">accepter les fichiers témoins</a> de catégorie Fonctionnel pour afficher cette fonctionnalité.</div></div>';
        })
      }
    }
  }, 1000, 10000);
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
