import { isMobile } from '../functions/helper';

export default class SlidingLine {
  constructor(object) {
    this.slidingLine = object;
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.options = {};

    // Direction que la ligne va prendre
    this.options.direction = this.slidingLine.direction === undefined ? 'horizontal' : this.slidingLine.direction;

    // Élément contenant la ligne
    this.options.container = this.slidingLine.container === undefined ? '[data-js="sliding-line-container"]' : this.slidingLine.container;

    // Élément sous lequel la ligne va glisser ou appaître (si c'est le premier hover)
    this.options.destination = this.slidingLine.destination === undefined ? '[data-js="sliding-line-destination"]' : this.slidingLine.destination;

    // Détermine s'il y a une position initiale
    this.options.startingPosition = this.slidingLine.startingPosition === undefined ? false : this.slidingLine.startingPosition;
  }

  init() {
    this.container = document.querySelector(this.options.container);
    if (this.container) {
      this.line = this.container.querySelector('[data-js="sliding-line"]');
      this.variableCreation();
      this.startingPosition();

      if (this.container !== undefined) {
        let i;
        this.destinations = document.querySelectorAll(this.options.destination);
        const destinationsLength = this.destinations.length;

        for (i = 0; i < destinationsLength; i += 1) {
          if (isMobile()) {
            this[`boundDestinationTouch${i}`] = (e) => this.onTouch(e);
            this.destinations[i].addEventListener('touchstart', this[`boundDestinationTouch${i}`], false);
          } else {
            this[`boundDestinationClick${i}`] = (e) => this.onClick(e);
            this[`boundDestinationHover${i}`] = (e) => this.onHover(e);
            this.boundHoverout = (e) => this.onHoverOut(e);

            this.destinations[i].addEventListener('click', this[`boundDestinationClick${i}`], false);
            this.destinations[i].addEventListener('mouseover', this[`boundDestinationHover${i}`], false);
            this.destinations[i].addEventListener('mouseout', this.boundHoverout, false);
          }
        }
      }
    }
  }

  // Assignation des variables selon la direction
  variableCreation() {
    if (this.options.direction === 'horizontal') {
      this.offsetPosition = 'offsetLeft';
      this.offsetDimension = 'offsetWidth';
      this.stylePosition = 'left';
      this.styleDimension = 'width';
    } else if (this.options.direction === 'vertical') {
      this.offsetPosition = 'offsetTop';
      this.offsetDimension = 'offsetHeight';
      this.stylePosition = 'top';
      this.styleDimension = 'height';
    }
  }

  // Animation de la ligne
  sliding() {
    const elementPosition = this.element[this.offsetPosition];
    const elementDimension = this.element[this.offsetDimension];

    this.line.style.opacity = '1';
    this.line.style[this.stylePosition] = `${elementPosition}px`;
    this.line.style[this.styleDimension] = `${elementDimension}px`;
  }

  // Assigne une valeur aux 2 variables qui vont être utilisées comme position et dimension de retour lors du hoverOut
  lastPosition() {
    this.lastClickPosition = this.element[this.offsetPosition];
    this.lastClickDimension = this.element[this.offsetDimension];
  }

  startingPosition() {
    // Détermine s'il y a une position de départ autre que la position initiale
    const initialPosition = this.options.startingPosition;

    // Défini l'élément de départ
    if (initialPosition) {
      // Création de la variable de l'élément de départ
      const startingElement = this.container.querySelector('.--active');

      // Assignation de l'élément de départ
      this.element = startingElement;

      this.lastPosition();
      this.sliding();
    }
  }

  onClick(e) {
    this.element = e.currentTarget;

    if (this.options.startingPosition !== 'initial') { this.lastPosition(); }
  }

  onTouch(e) {
    this.element = e.currentTarget;

    if (this.options.startingPosition !== 'initial') { this.lastPosition(); }
    this.sliding();
  }

  onHover(e) {
    this.element = e.currentTarget;
    this.line.style.transition = `${this.stylePosition} 400ms cubic-bezier(0.33, 1, 0.68, 1), ${this.styleDimension} 400ms cubic-bezier(0.33, 1, 0.68, 1), opacity 300ms 0ms`;

    this.sliding();
  }

  onHoverOut() {
    this.line.style.opacity = this.lastClickPosition === undefined ? '0' : '1';
    this.line.style.transition = `${this.stylePosition} 400ms cubic-bezier(0.33, 1, 0.68, 1), ${this.styleDimension} 400ms cubic-bezier(0.33, 1, 0.68, 1), opacity 300ms 300ms`;

    if (this.options.startingPosition !== 'initial') {
      this.line.style[this.styleDimension] = `${this.lastClickDimension}px`;
      this.line.style[this.stylePosition] = `${this.lastClickPosition}px`;
    }
  }

  destroy() {
    if (this.container !== undefined) {
      let i;
      this.destinations = document.querySelectorAll(this.options.destination);
      const destinationsLength = this.destinations.length;

      for (i = 0; i < destinationsLength; i += 1) {
        if (isMobile()) {
          this[`boundDestinationTouch${i}`] = (e) => this.onTouch(e);
          this.destinations[i].removeEventListener('touchstart', this[`boundDestinationTouch${i}`], false);
        } else {
          this[`boundDestinationClick${i}`] = (e) => this.onClick(e);
          this[`boundDestinationHover${i}`] = (e) => this.onHover(e);
          this.boundHoverout = (e) => this.onHoverOut(e);

          this.destinations[i].removeEventListener('click', this[`boundDestinationClick${i}`], false);
          this.destinations[i].removeEventListener('mouseover', this[`boundDestinationHover${i}`], false);
          this.destinations[i].removeEventListener('mouseout', this.boundHoverout, false);
        }
      }
    }
  }
}
