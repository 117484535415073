import $ from 'jquery';
import { resetDropzone } from './form';

// Fonction contenant tous les messages
function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      acceptMultiFile: 'The file must be a pdf document, png, jpg or jpeg image.',
      file: 'Please provide a adequate file.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      acceptMultiFile: 'Le fichier doit être un document pdf, png, jpg ou jpeg.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
    };
  }

  return messages;
}

// Fonction exécutant la validation de chaque formulaire
export function formValidation(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.c-form__grid-item').find('.js-form-error'));
    },
    success: (_, element) => {
      const errorField = $(element).closest('.c-form__grid-item').children();
      if (errorField.length) {
        const className = errorField.attr('class').split(' ')[0];
        errorField.removeClass(`${className}--error`);
      }
    },
    highlight: (element) => {
      // Ajouter la class d'erreur au champs
      const errorField = $(element).closest('.c-form__grid-item').children();
      if (errorField.length) {
        const className = errorField.attr('class').split(' ')[0];
        errorField.addClass(`${className}--error`);
      }
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select').on('change', function selectChange() {
    $(this).valid();
  });
  // Recaptcha recherche
  if (formElement == '#form-search' || formElement == '#form-search-page' || formElement == '#form-search-overlay') {
    $(submit).on('click', function () {
      grecaptcha.ready(function() {
        grecaptcha.execute('6LeyNsYqAAAAAMOu2rBWRdDHxC11MrgVJFH_o2-i', {action: 'submit'}).then( function(token) {
          $.ajax({
            url: '/blanko/recherche/captcha',
            data: {token: token},
          }).done(function(result){
            if (result.success) {
              $(formElement).submit()
            } else {
              alert(result.message)
            }
          })
        })
      })
      return false
    })
  } else {
    $(submit).on('click', function () {
      $(formElement).submit()
    })
  }
}

// Reset de validation
export function formSuccess(form, data) {
  const formElement = form;

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading');
    $('.stripe-loading-indicator').addClass('loaded');
    $(form)[0].reset();
    resetDropzone(form);
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha.reset();
    }

    // <div class="alert alert-success">x</div>

    let message = 'Le formulaire est bien envoyé !';
    const confirmationContainer = form.querySelector('.js-confirmation-message');

    if (confirmationContainer.innerHTML !== '') {
      message = confirmationContainer.innerHTML;
    } else if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
      message = 'The form has been sent !';
    }

    // Créer l'élément
    const confirmationNode = document.createElement('div');
    confirmationNode.className = 'alert alert-success';
    confirmationNode.textContent = message;

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode);

    // Cacher le formulaire
    formElement.style.display = 'none';

    // $.oc.flashMsg({text: message ,class: 'success'})
  }
}

// La validation des courriels
function validationEmail() {
  // eslint-disable-next-line func-names
  $.validator.addMethod('courriel', function (value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du formulaire d'exemple
export function formExample() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    radioExampleInline: { required: true },
    checkboxExample: { required: true },
  };
  const messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    radioExampleInline: { required: m.required },
    checkboxExample: { required: m.required },
  };

  formValidation('#form-example', '#form-example-submit', rules, messages);
}

// La validation du formulaire d'événement
export function formEvent() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownSectorEvent: { required: true },
    dropdownBuildingEvent: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    matricule: { required: true },
    email: { required: true, courriel: true },
    emailConfirmation: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    radioEventAllergiesInline: { required: true },
  };
  const messages = {
    dropdownSectorEvent: { required: m.required },
    dropdownBuildingEvent: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    matricule: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    emailConfirmation: { required: m.required, courriel: m.email, email: m.email },
    phone: { minlength: m.phone, required: m.required },
    radioEventAllergiesInline: { required: m.required },
  };

  formValidation('#form-event', '#form-event-submit', rules, messages);
}

export function formSearch(formID, submitID) {
  const m = getMessages();

  const rules = {
    q: { required: true },
  };
  const messages = {
    q: { required: m.required },
  };

  formValidation(formID, submitID, rules, messages);
}

// La validation du formulaire de contact
export function formContact() {
  validationEmail();

  const m = getMessages();
  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param);
  }, 'La taille maximale du fichier doit être 7MB');

  $.validator.addMethod('validateMultiFiles', function (element) {
    var valid = true;
    if (!element) {
      valid = !document.querySelector(':not(#form-example-files).js-multiple-files-field-button-input:not(.valid)');
    }
    return valid
  }, 'Le fichier doit être un document pdf, png, jpg ou jpeg.');

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    building: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    message: { required: true },
    'multiFile[]': { accept: 'pdf, png, jpg, jpeg', validateMultiFiles: true, filesize: 7340032 },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    building: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    message: { required: m.required },
    'multiFile[]': { accept: m.acceptMultiFile }
  };

  formValidation('#form-contact', '#form-contact-submit', rules, messages);
}

// La validation du formulaire d'infolettre
export function formNewsletter() {
  validationEmail();

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    emailConfirmation: { required: true, courriel: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    emailConfirmation: { required: m.required, courriel: m.email, email: m.email },
  };

  formValidation('#form-newsletter', '#form-newsletter-submit', rules, messages);
}

// La validation du formulaire d'exemple
export function formJobs() {
  validationEmail();

  const m = getMessages();

  const rules = {
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    cv: { required: true },
  };
  const messages = {
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { required: m.required, minlength: m.phone },
    cv: { required: m.required },
  };

  formValidation('#form-jobs', '#form-jobs-submit', rules, messages);
}
