import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '[data-js="open-overlay-share"]',
        close: '.close-overlay',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    alert('L’adresse URL a bien été copiée dans votre presse-papier.');
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');
  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }
  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.c-overlay-share__background, .c-overlay-share__close',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

export function overlayMenu() {
  window.overlayMenu = new Overlay({
    name: 'menu',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-menu"]',
        close: '[data-js="close-overlay-menu"]',
        switch: '[data-js="trigger-overlay-search"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  });

  window.overlayMenu.init();
}

export function overlaySearch() {
  const search = new Overlay({
    name: 'search',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        trigger: '[data-js="trigger-overlay-search"]',
        close: '[data-js="close-overlay-search"]',
        switch: '[data-js="trigger-overlay-menu"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  });

  search.init();

  const html = document.querySelector('html');
  let inputSearch;
  let inputValue;

  const onResize = () => {
    if (window.innerWidth <= 1000) {
      inputSearch = document.querySelector('.c-overlay-search [data-input="overlay-search-input"]');
      inputValue = document.querySelector('.c-overlay-search [data-inputvalue]');
    } else {
      inputSearch = document.querySelector('.header__container [data-input="overlay-search-input"]');
      inputValue = document.querySelector('.header__container [data-inputvalue]');
    }
  };

  function openOnInputFocus() {
    if (html.dataset.section !== 'suggestions' && html.dataset.section !== 'fast-results') {
      window.overlayMenu.switch();
      search.trigger('suggestions');
    }
  }

  const onOpenComplete = () => {
    inputSearch.focus();
  };

  const onCloseComplete = () => {
    inputSearch.value = '';
    inputValue.setAttribute('data-inputvalue', '');
  };

  let ajaxRequest = false;
  const changeSection = (e) => {
    const inputSearchElement = e;

    //-------
    if (ajaxRequest) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort();
    }
    //-------

    if (inputSearchElement.target.value.length >= 3 /* && html.dataset.section !== 'fast-results' */) {
      ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
        data: { q: e.currentTarget.value },
        update: { 'rechercheAutocomplete::autocomplete': '.c-overlay-search #autocomplete' },
        complete(data) {
          // Results loaded.
          ajaxRequest = false;

          // On affiche l'overlay des suggestions juste si ya des résultats
          if (data.responseJSON && data.responseJSON['rechercheAutocomplete::autocomplete']) {
            if (html.dataset.section !== 'fast-results') {
              search.trigger('fast-results');
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (html.dataset.section !== 'suggestions') {
              search.trigger('suggestions');
            }
          }
        },
      });

      // search.trigger('fast-results');
    } else if (inputSearchElement.target.value.length < 3 && html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  };

  const onClearInput = () => {
    if (html.dataset.section !== 'suggestions') {
      search.trigger('suggestions');
    }
  };

  OBSERVER.add({
    name: 'overlaySearch',
    events: 'resize load',
    function: onResize,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'focus',
    function: openOnInputFocus,
    targets: '[data-input="overlay-search-input"]',
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onOpenCompleteOverlaySearch',
    function: onOpenComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'onCloseCompleteOverlaySearch',
    function: onCloseComplete,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'input',
    targets: '[data-input="overlay-search-input"]',
    function: changeSection,
  });
  OBSERVER.add({
    name: 'overlaySearch',
    events: 'click',
    targets: '.js-overlay-search-clear-input',
    function: onClearInput,
  });
  OBSERVER.on('overlaySearch');
}

export function overlayFastlinks() {
  window.overlayFastlinks = new Overlay({
    name: 'fastlinks',
    events: {
      openComplete: true,
      closeComplete: true,
    },
    click: {
      buttons: {
        open: '[data-js="open-overlay-fastlinks"]',
        toggle: '[data-js="toggle-overlay-fastlinks"]',
        close: '[data-js="close-overlay-fastlinks"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  });

  window.overlayFastlinks.init();
}

export function overlayCalendar() {
  new Overlay({
    name: 'calendar',
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-calendar"]',
        close: '[data-js="close-overlay-calendar"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  }).init();
}

export function overlayConventionIndex() {
  new Overlay({
    name: 'convention-index',
    click: {
      buttons: {
        toggle: '[data-js="toggle-overlay-convention-index"]',
        close: '[data-js="close-overlay-convention-index"]',
      },
    },
    options: {
      speed: 800,
      closeOnResize: false,
      goToSelector: 'html, body',
    },
  }).init();
}

export function overlayPopup() {
  if (document.querySelector('[data-overlay="popup"]')) {
    const ovPopup = new Overlay({
      name: 'popup',
      events: {
        open: true,
        closeComplete: true,
      },
      click: {
        buttons: {
          close: '[data-js="close-overlay-popup"]',
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
      },
    });

    ovPopup.init();

    // Fonction permettant de donner un attribut height au container de l'overlay
    const addHeightAttributeToContainer = (nameEventOverlay, targetContainerOverlay) => {
      const containerOverlay = document.querySelector(targetContainerOverlay);

      function onOpen() {
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      function onCloseComplete() {
        containerOverlay.style.height = null;
      }

      function onResize() {
        containerOverlay.style.height = null;
        containerOverlay.style.height = `${containerOverlay.clientHeight}px`;
      }

      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onOpenOverlay${nameEventOverlay}`,
        function: onOpen,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: `onCloseCompleteOverlay${nameEventOverlay}`,
        function: onCloseComplete,
      });
      OBSERVER.add({
        name: `overlay${nameEventOverlay}`,
        events: 'resize',
        function: onResize,
      });
      OBSERVER.on(`overlay${nameEventOverlay}`);
    };

    // Fonction permettant de donner un attribut height au container de l'overlay (mettre events open et closeComplete à true)
    addHeightAttributeToContainer('Popup', '[data-overlay-container="popup"]');
  }
}
