import barba from '@barba/core';
import Swiper from 'swiper/bundle';
import { OBSERVER } from '../plugins';

export function swiperAlert() {
  // Vérifie l'existence du swiper
  const alertsExist = document.querySelector('.c-alerts');

  if (alertsExist) {
    const onClose = new CustomEvent('onCloseAlerts');

    if (document.querySelector('[data-js="alerts"]')) {
      setTimeout(() => {
        document.documentElement.style.setProperty('--alerts-height', '40px');
      }, 1000);
    }

    const closeAlerts = () => {
      dispatchEvent(onClose);
      document.documentElement.style.setProperty('--alerts-height', '0px');
    };

    OBSERVER.add({
      name: 'alerts',
      events: 'click',
      function: closeAlerts,
      targets: '[data-swiper="swiper-alerts-close"]',
    });
    OBSERVER.on('alerts');

    window['alerts-swiper'] = new Swiper('[data-swiper="alerts"]', {
      speed: 700,
      threshold: 10,
      slidesPerView: 'auto',
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: '[data-swiper="swiper-alerts-pagination"]',
        type: 'fraction',
      },
      navigation: {
        prevEl: '[data-swiper="swiper-alerts-prev"]',
        nextEl: '[data-swiper="swiper-alerts-next"]',
      },
      runCallbacksOnInit: true,
      on: {
        init(swm) {
          const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
          fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
        },
        slideChange(swm) {
          const fraction = document.querySelector('[data-swiper="swiper-alerts-pagination-fraction"]');
          fraction.innerHTML = `${swm.realIndex + 1}/${swm.slides.length - (swm.loopedSlides * 2)}`;
        },
      },
    });

    barba.hooks.afterLeave(() => {
      window['alerts-swiper'].destroy();
    });
  }
}

export function swiperHomeBanner() {
  // Vérifie l'existence du swiper
  const homeBannerExist = document.querySelector('.c-home-banner');

  if (homeBannerExist) {
    window['home-banner'] = new Swiper('[data-swiper="home-banner"]', {
      loop: (document.querySelectorAll('[data-swiper="home-banner"] .swiper-slide').length > 1),
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: '[data-swiper="swiper-home-banner-prev"]',
        nextEl: '[data-swiper="swiper-home-banner-next"]',
      },
    });

    barba.hooks.afterLeave(() => {
      window['home-banner'].destroy();
    });
  }
}

export function swiperHomeSector() {
  // Vérifie l'existence du swiper
  const homeSectorsExist = document.querySelector('.c-home-sectors__swiper');

  if (homeSectorsExist) {
    window['home-sectors'] = new Swiper('[data-swiper="home-sectors"]', {
      speed: 700,
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: false,
      scrollbar: {
        el: '[data-js="home-sectors-bar"]',
        draggable: false,
        snapOnRelease: false,
      },
      navigation: {
        prevEl: '[data-swiper="swiper-home-sectors-prev"]',
        nextEl: '[data-swiper="swiper-home-sectors-next"]',
      },
      autoplay: {
        delay: 2000,
      },
      breakpoints: {
        400: {
          spaceBetween: 25,
        },
        500: {
          spaceBetween: 30,
        },
      },
    });

    window.lazyload.update();

    barba.hooks.afterLeave(() => {
      window['home-sectors'].destroy();
    });
  }
}
