import Accordions from '../classes/accordions';
import { OBSERVER } from '../plugins';

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export default function rubrics() {
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.init();

  const closeRubric = () => {
    rubric.closeAll();
  };

  OBSERVER.add({
    name: 'scrollToBlock',
    events: 'click',
    targets: '.button-back-to-top',
    function: closeRubric,
  });

  OBSERVER.on('scrollToBlock');
}
