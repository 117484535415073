import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER } from './plugins';
import {
  backendKeyBind, calendar, ogContent, updateLazyLoad, changeSelect, tooltipPictureCredits, conventionsLoaded, galleriesLoaded, fullCalendar
} from './functions/functions';
import rubrics from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, inputsAndTextareaLabel, clearInput, tailSelect,
} from './functions/form';
import Overlay from './vendor/overlay';
import {
  formSearch, formContact, formEvent, formExample, formJobs, formNewsletter, formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { fontSizeBasedOnCaracters, hundredVH, removeHoverOnMobile } from './functions/helper';
import { swiperAlert, swiperHomeBanner, swiperHomeSector } from './functions/swiper';
import fadeTransition from './transitions/default-transition';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import {
  overlayCalendar,
  overlayFastlinks, overlayMenu, overlayPopup, overlaySearch, overlayShare, overlayConventionIndex,
} from './functions/overlays';
import { slidingLineFeaturedNews, slidingLineConvention, slidingLinePagination } from './functions/slidingLine';
import { typerSetup } from './vendor/typer';
import ShareButtons from 'share-buttons/dist/share-buttons';
import manageCookies from './functions/manageCookies';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.conventionsLoaded = conventionsLoaded;
window.galleriesLoaded = galleriesLoaded;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    backendKeyBind();
    SCROLLFIRE.init();
    removeHoverOnMobile();
    swiperAlert();
    inputsAndTextareaLabel();
    clearInput();
    clickToScrollToBlock({ selector: '[data-js="scroll-to-top"]' });
    fontSizeBasedOnCaracters();
    hundredVH();
    overlayShare();
    overlayMenu();
    overlaySearch();
    overlayFastlinks();
    overlayPopup();
    typerSetup();
    formSearch('#form-search', '#form-search-submit');
    formSearch('#form-search-overlay', '#form-search-overlay-submit');
    ShareButtons.update();
    manageCookies(); // MANAGE COOKIES / BYSCUIT
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [fadeTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          formsPackage();
          masksPackage();
          dynamicPackage();
          formExample();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-content"]', scrollTo: '[data-js="content"]' });
          window.lazyload = new LazyLoad();
          initFormBuilder();
          tooltipPictureCredits();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          window.lazyload = new LazyLoad();
          updateLazyLoad();
          swiperHomeBanner();
          swiperHomeSector();
          fontSizeBasedOnCaracters();
          slidingLineFeaturedNews();
          tooltipPictureCredits();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationsView',
        beforeEnter() {
          tailSelect();
          slidingLinePagination();
          changeSelect('#catFilterSelect');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'publicationView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventsView',
        beforeEnter() {
          tailSelect();
          slidingLinePagination();
          calendar();
          overlayCalendar();
          changeSelect('#catFilterSelect');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'eventView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          dynamicPackage();
          formEvent();
          initFormBuilder();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'conventionView',
        beforeEnter() {
          slidingLineConvention();
          rubrics();
          overlayConventionIndex();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          formsPackage();
          masksPackage();
          formContact();
          rubrics();
          clickToScrollToBlock({ selector: '[data-js="scroll-to-form"]', scrollTo: '[data-js="form"]' });
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          formSearch('#form-search-page', '#form-search-page-submit');
          tailSelect();
          clearInput();
          slidingLinePagination();
          changeSelect('#catFilterSelect');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formNewsletter();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobsView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'jobView',
        beforeEnter() {
          dynamicPackage();
          formsPackage();
          masksPackage();
          formJobs();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'galleryView',
        beforeEnter() {
          formsPackage();
          changeSelect('#catFilterSelect');
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'calendarView',
        beforeEnter() {
          fullCalendar();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
